<template>
  <div id="metwitt">
    <!-- <h1 class="logo-text">
      <img class="img-fluid metwitt-text" :src="metwittText" title="MeTwitt" alt="MeTwitt"/>
    </h1> -->
    <img class="img-fluid metwitt-logo" :src="logo" title="MeTwitt" alt="MeTwitt"/>
    <section class="sign-in-page">
      <b-container class="p-0 mt-4">
        <div class="row no-gutters mt-4">
          <div class="col-md-6 mt-4">
          </div>
          <div class="col-sm-6 pt-5">
            <div class="sign-in-from">
              <router-view></router-view>
            </div>
          </div>
        </div>
      </b-container>
      <footer>
        <ul class="d-flex justify-content-center">
          <li class="mr-3"><b-link>About</b-link></li>
          <li class="mr-3"><b-link>Help Center</b-link></li>
          <li class="mr-3"><b-link>Terms of Service</b-link></li>
          <li class="mr-3"><b-link>Privacy Policy</b-link></li>
          <li class="mr-3"><b-link>Cookie Policy</b-link></li>
          <li class="mr-3"><b-link>Accessibility</b-link></li>
          <li class="mr-3"><b-link>Ads Info</b-link></li>
          <li class="mr-3"><b-link>Status</b-link></li>
          <li class="mr-3"><b-link>Advertising</b-link></li>
          <li class="mr-3"><b-link>Marketing</b-link></li>
          <li class="ml-2">&#169; {{ currentYear }} <a href="/">MeTwitt</a></li>
        </ul>
      </footer>
    </section>
  </div>
</template>

<script>

import logo from '@/assets/images/metwitt-logo.svg'
import metwittText from '@/assets/images/metwitt-text.svg'
import FooterMenuItems from '@/FackApi/json/FooterMenu'

export default {
  name: 'AuthLayout',
  components: { },
  data () {
    return {
      animated: { enter: 'zoomIn', exit: 'zoomOut' },
      logo: logo,
      metwittText: metwittText,
      footerMenu: FooterMenuItems
    }
  },
  computed: {
    currentYear () {
      return new Date().getFullYear()
    }
  }
}
</script>

<style lang="scss" scoped>
ul {
  list-style: none;
}

footer {
  position: fixed;
  bottom: 0;
  width: 100%;
}
</style>
